import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@bp-core/src/lib/services/portal/auth.service';

import { OAuthService } from 'projects/bp-core/src/lib/external/angular-oauth2-oidc-jwks/oauth-service';
import { filter, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    public authService: AuthService,
    private router: Router,
    private oauthService: OAuthService,
  ) {}

  canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.authService.hasDiscoveredSubject$
        .pipe(
          filter(e => e === true),
          take(1),
        )
        .subscribe(async () => {
          if (this.oauthService.hasValidIdToken() || this.oauthService.hasValidAccessToken()) {
            try {
              await this.oauthService.refreshToken();

              //  this.oauthService.checkSession();
              return this.authService.userSubject$.pipe(take(1)).subscribe(user => {
                console.log('user', user);
                console.log('admin', user?.isAdmin);
                if (user && user.isAdmin) {
                  return resolve(true);
                } else if (!user) {
                  this.authService.refreshUser().subscribe(
                    data => {
                      console.log('user', data);
                      console.log('admin', data?.isAdmin);
                      if (data && data.isAdmin) {
                        return resolve(true);
                      } else {
                        this.router.navigate(['/errors/401']);
                        return resolve(false);
                      }
                    },
                    error => {
                      console.error(error);
                      return resolve(false);
                    },
                  );
                } else {
                  return resolve(false);
                }
              });
            } catch (error) {
              console.error(error);
              this.oauthService.initCodeFlow(window.location.href);
              return resolve(false);
            }
          } else {
            console.log(
              'canActivate',

              this.oauthService.hasValidIdToken(),
              this.oauthService.hasValidAccessToken(),
            );

            this.oauthService.initCodeFlow(window.location.href);
            return resolve(false);
          }
        });
    });
  }
}
